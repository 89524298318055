body {
  background-color: #012828;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.background {
  background-image: url(./imeg.jpeg);
  filter: opacity(0.7) brightness(0.25);
  position: absolute;
  inset: 0px;
}

html {
  background-color: #012828;
}

.main {
  min-height: 100dvh;
  width: 100%;
  position: relative;
}

.logo {
  position: absolute;
  right: 152px;
  top: 174px;
  filter: drop-shadow(0px 0px 9.41px #fefb9aab)
    drop-shadow(0px 0px 18.82px #fefb9aab)
    drop-shadow(0px 0px 65.88px #fefb9aab);
  z-index: 2;
}

.border {
  position: absolute;
  transform: translateY(-50%);
}

.border-niz {
  position: absolute;
  bottom: 0px;
  transform: scaleY(-1) translateY(-50%);
  z-index: 1;
}

p {
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 4rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 80px;
}

.header-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 20px;
  padding-top: 30px;
}

.header-logo p {
  line-height: 2rem;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 50px;
  row-gap: 50px;
  width: 996px;
}

.info-full {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  /* inset: 0px; */
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
}
.info-header {
  text-align: center;
}

.info-block {
  border-radius: 10px;
  padding: 20px 30px;
  background: #81818166;
  display: flex;
  flex-direction: column;
  gap: 20px;

  border-image-source: linear-gradient(
    90.78deg,
    #0f231d -6.13%,
    #a2a2a2 49.31%,
    #0f231d 104.74%
  );
  border: 1px solid;
  backdrop-filter: blur(14px);
}
.info-prem {
  display: flex;
  gap: 80px;
  width: 1396px;
  max-width: 90% !important;
  /* flex-wrap: wrap; */
}
.c1 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
  max-width: 500px;
}

.c2 {
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
}

textarea:focus,
input:focus {
  outline: none;
}

:root {
  --top: -120px;
}

@media only screen and (max-width: 1220px) {
  .info-prem {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    width: 90% !important;
    flex-wrap: wrap;
  }
  .c1 {
    max-width: unset !important;
  }

  :root {
    --top: -111px;
  }

  /* .border1 {
    top: -111px !important;
  } */
}

@media only screen and (max-width: 1024px) {
  .hide1024 {
    display: none !important;
  }
  .center1024 {
    align-self: center !important;
    text-align: center !important;
  }
  .header {
    padding-right: 20px;
    padding-left: 20px;
  }
  .show1024 {
    display: flex !important;
    margin-right: auto !important;
    margin-left: auto !important;
    align-self: center;
    z-index: 2;
  }
  .show1024 p {
    width: unset !important;
  }
  .mainCenter1024 {
    display: flex !important;
    flex-direction: column !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .logoCenter1024 {
    width: 100%;
    height: unset !important;
  }
  .logo2Center1024 {
    width: 80% !important;
  }
  .info {
    display: flex;
    flex-wrap: wrap !important;
    flex-direction: column;
    width: 90% !important;
    row-gap: 20px;
  }
  .logo {
    z-index: -1;
  }

  .form {
    width: unset !important;
    min-width: unset !important;
  }

  .small {
    font-size: 40px !important;
    margin-bottom: 20px;
  }

  .info-prem {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    width: 90% !important;
    flex-wrap: wrap;
  }

  .smallCenter1024 {
    flex-wrap: wrap;
    max-width: unset !important;
  }

  .logo2 {
    position: unset !important;
    width: unset !important;
  }

  .logo2 div {
    position: unset !important;
    height: 660px !important;
    width: 100% !important;
  }

  .c1 {
    width: unset !important;
    min-width: unset !important;
  }
  .c2 {
    width: unset !important;
    min-width: unset !important;
  }
  :root {
    --top: -80px;
  }
}

@media only screen and (max-width: 768px) {
  .hide760 {
    display: none;
  }
  .header {
    justify-content: center;
  }
  .logo {
    right: 0;
    left: 0;
    top: 150px;
  }
  .show1024 {
    width: 90% !important;
    margin-bottom: 0% !important;
  }
  .center1024 {
    font-size: 35px !important;
  }

  p {
    overflow-wrap: anywhere;
  }
  .button {
    width: 200px !important;
  }

  .smallHeader {
    line-height: 1.5rem !important;
    font-size: 16px !important;
    text-align: center;
  }

  .price {
    font-size: 45px !important;
  }

  .mainLogoText {
    height: 75% !important ;
  }
  :root {
    --top: -60px;
  }
}

@media only screen and (max-width: 600px) {
  .hide600 {
    display: none;
  }
  .logo2Center1024 {
    left: 0px !important;
  }
  .logo {
    top: 400px !important;
  }
  .header-logo p {
    line-height: 3rem;
    text-align: center;
  }
  :root {
    --top: -60px;
  }
}

@media only screen and (max-width: 425px) {
  :root {
    --top: -50px;
  }
  .center1024 {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 380px) {
  :root {
    --top: -50px;
  }
}
